@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.mobile-header {
    flex-direction: column;

    @include media-query-grid(md) {
        flex-direction: row;
    }
}
