@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.truncate-wrapper {
    .toggle-control {
        &:hover {
            cursor: pointer;
        }

        text-decoration: underline;
    }
}
