@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.contact-button {
    display: flex;
    width: inherit;

    &[disabled] {
        color: $white;
    }
}

.button-with-gradient {
    display: flex;
    width: inherit;
    background: #222325;
    z-index: 1;
    border: transparent;

    &::before {
        position: absolute;
        content: '';
        inset: 0;
        background: radial-gradient(
            89.29% 169.2% at -2.38% 0%,
            #ffab61 0%,
            #f4b5c5 25%,
            #85a6ff 50%,
            #3b80db 73.5%,
            #000 100%
        );
        z-index: -1;
        transition: opacity 0.3s linear;
        border-radius: 8px;
        opacity: 0;
    }

    &[disabled] {
        color: $white;
    }

    &:hover::before {
        opacity: 1;
    }
}
