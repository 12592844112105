@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.contact-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    min-width: 100%;
    width: 100%;
    text-align: center;
    border: 1px solid color($grey, 400);
    border-radius: 4px;

    .contact-button {
        width: 100%;
    }
}

.avatar-box {
    display: flex;
    text-align: left;
    min-width: 100%;
}
