@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.icon {
    margin-right: 8px;
}

.consultation-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.consultation-container {
    width: 100%;
}
