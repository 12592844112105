@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.header {
    margin-bottom: 24px;

    @include media-query-grid(md) {
        margin-bottom: 48px;
    }
}

.notable-clients {
    margin-top: 40px;

    @include media-query-grid(md) {
        margin-top: 48px;
    }
}

.info-buttons {
    display: none;

    @include media-query-grid(md) {
        display: block;
    }

    .button-contact {
        width: max-content;
    }
}

.header-wrapper {
    margin-bottom: 24px;

    @include media-query-grid(md) {
        margin-bottom: 0;
    }
}

.trader-info-wrapper {
    margin-top: 40px;

    @include media-query-grid(sm) {
        margin-top: 48px;
    }
}

.mobile-contact {
    margin-bottom: 80px;
}

:global(div.notable-clients .dynamic-scrollbar-wrapper .scrolled-list) {
    margin: 0;
    padding: 0;
}
