@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
$border: rgb(239, 239, 240);

.container {
    width: 100%;
    box-sizing: border-box;
    color: color($grey, 1200);

    @include media-query-grid(md) {
        border-radius: 4px;
        border: 1px solid $border;
        padding: 32px;
    }
}
