@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.divider {
    margin-bottom: 40px;

    @include media-query-grid(md) {
        margin-top: 32px;
        margin-bottom: 32px;
        width: 100%;
        height: 1px;
        background: color($grey, 500);
    }
}
