@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
$icon_size: 48px;
$margin: 12px;

.header {
    margin-top: 40px;
    line-height: 25px;
    font-size: 16px;

    @include media-query-grid(md) {
        margin-top: 48px;
        font-size: 18px;
    }
}

.contents-wrapper {
    // sass-lint:disable no-misspelled-properties
    row-gap: 16px;
}

.education-icon-wrapper {
    height: 40px;
    width: 40px;
    background-color: color($grey, 300);
    border-radius: 4px;
    flex-shrink: 0 0 40px;
}

.info {
    flex-grow: 1;
    width: 100%;

    &.with-icon {
        margin-left: $margin;
        width: calc(100% - #{$icon_size} - #{$margin});
    }

    .description {
        color: color($grey, 900);
        line-height: 14px;
    }

    .title {
        color: color($grey, 1100);
    }

    .separator {
        @include media-query-grid(md) {
            border-top: 1px solid color($grey, 400);
            width: 100%;
        }
    }
}
