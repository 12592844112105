@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
$list_item_padding_right: 8px;

.list {
    flex-wrap: wrap;
    width: 100%;
}

.icon-container {
    align-self: flex-start;
    height: 24px;
}

.subcategory-label {
    color: color($grey, 1100);
}

.title {
    color: color($grey, 1200);
}

.item {
    flex-basis: 100%;
    padding: 0 $list_item_padding_right 12px 0;

    @include media-query-grid(md) {
        flex-basis: calc(50% - #{$list_item_padding_right});
    }
}
