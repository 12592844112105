@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.profile-header {
    .user-info {
        align-self: baseline;
        display: flex;
        flex-direction: column;

        // sass-lint:disable no-misspelled-properties
        row-gap: 8px;
    }

    .center {
        align-self: center;
    }

    .username {
        display: initial;

        @include media-query-grid-reverse(lg) {
            display: none;
        }
    }
}

.profile-badge-ratings {
    flex-wrap: wrap;
    row-gap: 8px;

    &.profile-rating {
        .rating {
            .star {
                width: 16px !important;
                height: 16px !important;
            }

            .score,
            .counts {
                font-size: 16px;
                line-height: 24px;
            }

            .counts {
                color: color($grey, 900);
            }
        }
    }
}

.online-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: color($green);
}
