@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.trigger {
    display: inline-block;
    color: color($grey, 1200);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: color($grey, 1200);
    }
}
