@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '~@fiverr-private/seller_pages_toolbox/src/components/z-index-values';

.sticky-footer {
    background: $white;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zIndexStickyHeader; // more than default div.top-clients-tooltip-container
    box-sizing: border-box;
    flex-wrap: wrap;

    .contact-btn {
        width: auto;
    }

    @include media-query-grid(xs) {
        padding-top: 16px;
        padding-bottom: 16px;

        // sass-lint:disable no-misspelled-properties
        row-gap: 12px;

        .contact-btn {
            width: 100%;
            max-width: 272px;
        }
    }
}
