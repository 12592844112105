@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.seller-page {
    padding-bottom: 24px;

    .sticky-header {
        position: absolute;
    }

    .grid-about-section {
        display: flex;
        margin-bottom: 64px;

        @include media-query-grid(md) {
            display: grid;
            grid-template-columns: calc(100% - 357px - 24px) 357px;
        }

        @include media-query-grid(lg) {
            grid-template-columns: calc(100% - 423px - 24px) 423px;
        }

        &.with-second-column {
            justify-content: space-between;
        }

        .first-column {
            max-width: 100%;
        }

        .second-column {
            display: none;

            @include media-query-grid(md) {
                display: block;
                flex: inherit;
            }
        }
    }

    .sub-header-overview {
        position: relative;
    }

    .work-experience {
        @include media-query-grid(md) {
            margin-top: 32px;
        }
    }

    .portfolio {
        margin-top: 32px;
        margin-bottom: 32px;
    }

    .reviews {
        @include media-query-grid(xs) {
            margin-top: 8px;
            margin-bottom: 64px;
        }

        @include media-query-grid(md) {
            margin-top: 64px;
            margin-bottom: 32px;
        }

        &.with-testimonials {
            @include media-query-grid(xs) {
                margin-bottom: 32px;
            }

            @include media-query-grid(md) {
                margin-bottom: 48px;
            }
        }
    }

    .buttons-group {
        justify-content: end;

        @include media-query-grid(md) {
            margin-bottom: 32px;
        }
    }

    .highlights {
        @include media-query-grid-reverse(md) {
            display: none;
        }
    }

    .no-cover-photo {
        margin-top: 53px;

        @include media-query-grid-reverse(lg) {
            margin-top: 32px;
        }
    }
}

.hide-md {
    @include media-query-grid(md) {
        display: none;
    }
}

.first-column-max-width {
    max-width: 800px;
}
