@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.icon {
    width: 40px;
    height: 40px;
}

.fiverr-learn-logo {
    display: inline-block;
}

.title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include media-query-grid(md) {
        justify-content: space-between;
    }
}
