@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.seller-overview {
    &.info {
        align-self: center;
        padding-top: 32px;

        @include media-query-grid-reverse(md) {
            padding-top: 24px;
        }

        @include media-query-grid-reverse(sm) {
            padding-top: 12px;
        }
    }

    .avatar-container {
        &.new-view {
            margin-top: 0 !important;
        }

        &.md {
            margin-top: -60px;
        }

        &.sm {
            margin-top: -40px;
        }
    }
}
