@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.carousel {
    :global(.slides-list) {
        &::before,
        &::after {
            content: '';
            height: 100%;
            position: absolute;
            top: 0;
            width: $spacing_6;
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.35s ease;
        }

        &::after {
            right: -#{$spacing_6};
            background-image: linear-gradient(to left, $white, 85%, transparent);
        }

        &::before {
            left: -#{$spacing_6};
            z-index: 1;
            background-image: linear-gradient(to right, $white, 85%, transparent);
        }

        @include media-query-grid(sm) {
            &::before,
            &::after {
                width: $spacing_8;
            }

            &::after {
                right: -#{$spacing_8};
                background-image: linear-gradient(to left, $white, 85%, transparent);
            }

            &::before {
                left: -#{$spacing_8};
                z-index: 1;
                background-image: linear-gradient(to right, $white, 85%, transparent);
            }
        }
    }

    &.is-touch {
        :global(.slides-list) {
            margin-left: -#{$spacing_6};
            margin-right: -#{$spacing_6};

            &::before,
            &::after {
                opacity: 1;
            }
        }
    }

    &:not(.is-touch) {
        margin-left: -#{$spacing_6};
        margin-right: -#{$spacing_6};
        padding-left: $spacing_6;
        padding-right: $spacing_6;
        overflow: hidden;

        :global(.slides-list) {
            position: relative;
            overflow: visible;
        }
    }
}

.carousel-track {
    .is-touch & {
        padding: 0 $spacing_6 0 $spacing_6 !important;
        scroll-padding-left: $spacing_6;
        scroll-padding-right: $spacing_6;
    }
}

.carousel-can-scroll-left {
    :global(.slides-list) {
        &::before {
            opacity: 1;
        }
    }
}

.carousel-can-scroll-right {
    :global(.slides-list) {
        &::after {
            opacity: 1;
        }
    }
}
