@import '../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
@import '~@fiverr-private/sass/classes/index';
@import '~@fiverr-private/seller_pages_toolbox/src/components/z-index-values';

@keyframes move-from-top {
    from {
        top: -50px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

.sticky-header {
    background: $white;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: $zIndexStickyHeader; // more than default div.top-clients-tooltip-container
    display: none;
    animation: move-from-top 0.2s ease-in-out;

    &.active-header {
        display: block;
    }

    .section-container {
        background: inherit;
        position: relative;
        padding-top: 16px !important;
    }

    .md-visible {
        @include media-query-grid-reverse(md) {
            display: none;
        }
    }

    .shadow-border {
        width: 100%;
        position: relative;
        background: color($grey, 400);
        height: 1px;
        top: -1px;
    }

    .more-button {
        border-color: color($grey, 400);

        &:hover {
            border-color: transparent;
        }
    }

    .response-info {
        position: absolute;
        right: 8px;

        @include media-query-grid(lg) {
            right: 16px;
        }
    }

    .button-group {
        margin-top: 6px;
    }
}
