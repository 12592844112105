@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.buttons-group {
    column-gap: 16px;
}

.dots-menu-button-wrapper {
    .control-button {
        display: flex;
        padding: 12px;
    }
}

.save-seller-btn {
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
}
