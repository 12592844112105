@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
$avatar_border_size: 4px;

.user-avatar {
    display: inline-block;
}

.avatar-container-border {
    border: #{$avatar_border_size} solid $white;
    top: #{$avatar_border_size};
    left: calc(-1 * #{$avatar_border_size});
}

.intro-video-play-button {
    &.lg {
        width: 40px;
        height: 40px;
    }

    &.md {
        width: 32px;
        height: 32px;
        right: -4px;
        bottom: -2px;
    }

    &.sm {
        width: 24px;
        height: 24px;
        right: -6px;
        bottom: -6px;
    }
}
