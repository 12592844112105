@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.suggested {
    text-transform: uppercase;
    line-height: 18px;
}

.tag {
    cursor: pointer;
}

.error {
    color: color($red, 800);
    line-height: 21px;
}

.input {
    width: 100%;
}

.layout {
    overflow-y: auto;
}

.link,
.link:hover {
    color: color($blue, 700);
    text-decoration: underline;
}
