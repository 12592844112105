@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.container,
.proposition-points {
    &.large-spacing {
        margin-bottom: 12px;

        @include media-query-grid(md) {
            margin-bottom: 24px;
        }
    }

    .point {
        padding-bottom: 12px;
    }
}
