@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/generated/generated-index';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/colors';
@import '../../../../../../../node_modules/@fiverr-private/sass/helpers/media_queries';
.about-me {
    box-sizing: border-box;

    .title {
        font-size: 16px;
    }

    .description {
        line-height: 1.5em;
        color: color($grey, 1100);
        word-break: break-word;

        &.clamp {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-height: 3em;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
